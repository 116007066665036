<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" />
    </q-breadcrumbs>

  <div class="row justify-between items-end q-mb-sm q-mt-md">
    <div class="col full-height text-h5">
      Total Jobs
      {{numRecs}}
      <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}} Posts
      </span>
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <job-list-item v-for="post in recs" :key="post._id" :post="post"></job-list-item>
  </table>

  <div class="row justify-end items-end q-mb-sm q-mt-md">

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>


</div>
</template>

<script>
// import { PostActionTypes } from "../../store/action-types";
import jobModule from "../../store";
// import { mapState, mapGetters } from 'vuex';
import storeRecListMixin from "src/mixins/store_rec_list.mixin";


export default {
  mixins: [
    storeRecListMixin,
  ],
  props: {
    'postType': {
      type: String,
      default: null,
      required: false
    },
  },
  data() {
    let qry = { };
    // if (this.postType) {
    //   // qry['post_type'] = this.postType;
    //   this.$set(qry, 'post_type', this.postType);
    //   console.log('setting post type to ', this.postType, qry);
    // }

    return {
      collection: 'job',
      vuexCollection: 'job.recs',
      vuexPath: 'job',
      vuexPageRequestAction: jobModule.actionTypes.LoadRecordsPageRequest,

      qry: {
        l: true,
      }
    }
  },
  computed: {
      // ...mapState('post', {
      //   loading: 'loading',
      // }),
      // ...mapGetters('post', [
      //   'hasPageSet',
      // ]),
      // currentPage() {
      //   return +this.$route.params.pageId;
      // },
      // numJobs() {
      //   return this.$store.getters['post/totalRecords'];
      // },
      // numPages() {
      //   return Math.ceil(this.numJobs / 25);
      // },
  },

  watch: {
    // call again the method if the route changes
    // '$route': function() { this.reqData(); },
  },

  created () {
    console.log("in created list");
    this.qry = {};
    // this.reqData();
  },

  methods: {
    bookmark (postId) {
      console.log('bookmark post', postId);
    },
    // gotoPage (pageId) {
    //   this.$store.dispatch( 'post/' + PostActionTypes.PostsLoadRecordsPageRequest, { page_id: +pageId } );
    //   this.$router.push({ name: 'jobList', params: { pageId }});
    // },
    // reqData (force) {
    //   if (! this.hasPageSet(this.$route.params.pageId) || force) {
    //     this.$store.dispatch( 'post/' + PostActionTypes.PostsLoadRecordsPageRequest, { page_id: this.$route.params.pageId } );
    //   }
    // },

  },
}
</script>



